<template>
  <v-container class="d-flex flex-column pa-8">
    <div class="header-top">
      <h2 class="view-title">Request a Featuare</h2>
    </div>
    <v-card>
      <v-card-text>
        <v-form ref="form" class="form-report">
          <v-text-field
            v-model="name"
            label="Name *"
            outlined
            placeholder="Name"
            hint="Name"
            persistent-hint
            hide-details="auto"
            :rules="[validators.required]"
          />
          <v-text-field
            v-model="location"
            label="Where would you like the feature to be located? (URL)"
            outlined
            placeholder="Where would you like the feature to be located? (URL)"
            hint="Where would you like the feature to be located? (URL)"
            persistent-hint
            hide-details="auto"
            :rules="[validators.urlValidator]"
          />
          <v-textarea
            v-model="description"
            outlined
            label="Description *"
            placeholder="Description"
            hint="Description"
            persistent-hint
            hide-details="auto"
            :rules="[validators.required]"
          />
          <label class="attach-text">Attach files</label>
          <vue-dropzone
            ref="rbDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-success="dropzoneOnSuccess"
            @vdropzone-upload-progress="dropzoneUploadProgress"
            @vdropzone-file-added="dropzoneFileAdded"
            @vdropzone-complete="dropzoneComplete"
            @vdropzone-canceled="dropzoneCanceled"
          />

          <v-alert v-if="errors && errors.length > 0" class="mt-4" color="error" text>
            <p class="font-weight-semibold mb-1">Messages</p>
            <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
              {{ error }}
            </p>
          </v-alert>

          <v-btn color="primary" class="btn-submit" @click="submit" :disabled="loading">
            <span v-if="loading === false">Submit</span>
            <span v-else>
              <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
              <span>Sending request...</span>
            </span>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
import { mapActions } from 'vuex'
import { mdiClose } from '@mdi/js'
import { required, urlValidator } from '@core/utils/validation'
//Dropzone
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      form: null,
      data: {},
      loading: false,
      validators: {
        required,
        urlValidator,
      },

      name: '',
      location: '',
      description: '',
      files: [],

      // errors
      message: '',
      errors: [],

      dropzoneOptions: {
        url: process.env.VUE_APP_BACKEND_URL + '/api' + '/file/upload',
        addRemoveLinks: true,
        maxFiles: 5,
        acceptedFiles: '.jpeg,.jpg,.png,.gif,.pdf',
        maxFilesize: 12,
        timeout: 0,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.accessToken}`,
        },
      },

      dropzoneTotalFilesize: 0,
      dropzoneUploadedFilesize: 0,
      dropzoneCurrentUpload: 0,
    }
  },
  computed: {
    uploadProgress() {
      return Math.round(
        ((this.dropzoneUploadedFilesize + this.dropzoneCurrentUpload) / this.dropzoneTotalFilesize) * 100,
      )
    },
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/set',
      create: 'request_feature/create',
    }),
    async submit() {
      if (this.$refs.form.validate()) {
        const form_data = {
          name: this.name,
          location: this.location,
          description: this.description,
          file_uploads: this.files,
        }

        this.loading = true
        await this.create(form_data)
          .then(resp => {
            if (resp && resp.data) {
              console.log(resp.data)
              if (resp.data.status == 'ok') {
                this.setSnackbar({
                  status: true,
                  text: 'The application was sent successfully.',
                  color: 'primary',
                })

                this.errors = null
                this.cleanForm()
              }
            }
          })
          .catch(error => {
            this.setSnackbar({
              status: true,
              text: 'The request could not be submitted.',
              color: 'error',
            })

            if (error.response.data && error.response.data.status === 'error') {
              this.errors = error.response.data.errors
            } else {
              this.errors = null
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    dropzoneFileAdded(file) {
      this.dropzoneTotalFilesize += file.size
    },
    dropzoneUploadProgress(file, totalBytes, totalBytesSent) {
      this.dropzoneCurrentUpload = totalBytesSent // write totalBytes to dropzoneCurrentUpload
      if (file.size <= totalBytesSent) {
        this.dropzoneCurrentUpload = 0 // reset current upload bytes counter
        this.dropzoneUploadedFilesize += totalBytesSent // add finished file to total upload
      }
    },
    dropzoneOnSuccess(file, success) {
      const response = JSON.parse(file.xhr.responseText)
      this.files.push(response.file_id)
    },
    dropzoneComplete(file) {},
    dropzoneCanceled(file) {},
    dropzoneError(file, message, xhr) {},
    async cleanForm() {
      await this.$refs.form.reset()
      this.$refs.rbDropzone.removeAllFiles()
      this.files = []
    },
  },
}
</script>

<style scoped>
.header-top {
  margin-bottom: 20px;
}
.btn-more {
  padding: 0px !important;
  word-break: break-word !important;
}
.btn-submit {
  margin-top: 20px;
}

.attach-text {
  margin: 10px 10px;
  font-size: 12px;
}
.vue-dropzone {
  background: transparent;
}
.dropzone {
  background: transparent;
  border: 1px solid #595572;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #aca8c3;
}
</style>
